import React from 'react'
import styled from 'styled-components';

import MainLayout from '../components/Layouts/MainLayout/MainLayout'
import Seo from '../components/Misc/Seo/Seo'
import { Heading, P } from '../components/Misc/Typogrpahy/Typography';

const Section = styled.section`
  text-align: center;
  padding: 18rem 0;
`;

const NotFoundPage = () => (
  <MainLayout>
    <Seo title='404: Not found' />
    <Section>
      <Heading as={'h2'}>404: NOT FOUND</Heading>
      <P>The page that you were looking for doesn&#39;t exist...</P>
    </Section>
  </MainLayout>
)

export default NotFoundPage
